import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { PAGES } from '../../config/pages';
import { mapUserToProps } from '../../redux/actions';
import { toggleClass } from '../../utils/Document';

class Sidebar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sidebarOpen: true,
            expanded: [1, 2, 3, 4]
        };
    }

    expand = (num) => (evt) => {
        evt.preventDefault();
        if (this.state.expanded.indexOf(num) > -1) {
            this.setState({ expanded: this.state.expanded.filter(x => { return x !== num }) });
        }
        else {
            this.setState({
                expanded: [
                    ...this.state.expanded,
                    num
                ]
            });
        }
    }

    minimize = (e) => {
        toggleClass('main', 'sidebar-collapse');
        this.setState({ sidebarOpen: !this.state.sidebarOpen });
    }

    //TODO: once Tools created, the renderToolbox() should be working well.
    renderToolbox = () => {
        return (
            <ul className="treeview-menu">
                {
                    // TODO: new Tools will be displayed once created
                    //this.props.user && this.props.user.AuthorizedPages && this.props.user.AuthorizedPages.indexOf(PAGES.ChaseGeneration) > -1 &&
                    <li>
                        <NavLink to="/GapChase/Lists/Chase">
                            <span>Chase Generation</span>
                        </NavLink>
                    </li>
                }
                {
                    // TODO: new Tools will be displayed once created
                    //this.props.user && this.props.user.AuthorizedPages && this.props.user.AuthorizedPages.indexOf(PAGES.GapIdentification) > -1 &&
                    <li>
                        <NavLink to="/GapChase/Lists/Gap">
                            <span>Gap Identification</span>
                        </NavLink>
                    </li>
                }
                {
                    // TODO: new Tools will be displayed once created
                    //this.props.user && this.props.user.AuthorizedPages && this.props.user.AuthorizedPages.indexOf(PAGES.IncExcLists) > -1 &&
                    <li>
                        <NavLink to="/GapChase/InclusionExclusion">
                            <span>Inclusion/Exclusion Lists</span>
                        </NavLink>
                    </li>
                }
            </ul>
        );
    }

    renderOperationalReports = () => {
        return (
            <ul className="treeview-menu">
                {/* Commented this block because for now we don't have any outputs for this. */}
                {/* {
                    //this.props.user && this.props.user.AuthorizedPages && this.props.user.AuthorizedPages.indexOf(PAGES.UserActivity) > -1 &&
                    <li>
                        <NavLink to="#">
                            <span>Data Loader Activity</span>
                        </NavLink>
                    </li>
                } */}
                {
                    this.props.user && this.props.user.AuthorizedPages && this.props.user.AuthorizedPages.indexOf(PAGES.UserActivity) > -1 &&
                    <li>
                        <NavLink to="/Reporting/UserActivity">
                            <span className='toolbar-section-title'>User Activity</span>
                        </NavLink>
                    </li>
                }
            </ul>
        );
    }

    renderHelp = () => {
        return (
            <ul className="treeview-menu">
                {
                    <li>
                        <NavLink to="/Help/UserManual">
                            <span className='toolbar-section-title'>User Manual</span>
                        </NavLink>
                    </li>
                }
            </ul>
        );
    }

    renderPlanReports = () => {

        var pages = [];

        pages.push({ key: PAGES.enrollmentreconciliationreport, label: "Enrollment Reconciliation", url: "/Reporting/EnrollmentReconciliation", });
        pages.push({ key: PAGES.financialprofilereport, label: "Financial Profile", url: "/Reporting/FinancialProfile" });
        pages.push({ key: PAGES.gapprofilereport, label: "Gap Profile", url: "/Reporting/GapProfile" });
        pages.push({ key: PAGES.memberprofilereport, label: "Member Profile", url: "/Reporting/MemberProfile" });
        pages.push({ key: PAGES.planprofilereport, label: "Plan Profile", url: "/Reporting/PlanProfile" });
        pages.push({ key: PAGES.providerprofilereport, label: "Provider Profile", url: "/Reporting/ProviderProfile" });

        return (
            <ul className="treeview-menu">

                {
                    pages.map(page =>
                        this.props.user && this.props.user.AuthorizedPages && this.props.user.AuthorizedPages.indexOf(page.key) > -1 &&
                        <li>
                            <NavLink to={page.url}>
                                <span> {page.label} </span>
                            </NavLink>
                        </li>

                    )

                }

            </ul>
        );
    }

    render() {
        return (
            <aside className="main-sidebar">
                <section className="sidebar">
                    <ul className="sidebar-menu">
                        {/*TODO: Will display this section once we have new items created*/}
                        <li className={this.state.expanded.indexOf(1) > -1 ? "treeview active" : "treeview"}>
                            <a href="#" onClick={this.expand(1)}>
                                <FontAwesomeIcon icon="toolbox" fixedWidth />
                                <span className='toolbar-section-title'>Suspect Toolbox</span>
                                <span className="pull-right-container">
                                    <FontAwesomeIcon icon={(this.state.expanded.indexOf(1) > -1) ? "angle-down" : "angle-left"} fixedWidth />
                                </span>
                            </a>
                            {this.renderToolbox()}
                        </li>
                        {(localStorage.getItem('Lob') != 'Medicare') &&
                            <li className={this.state.expanded.indexOf(2) > -1 ? "treeview active" : "treeview"}>
                                <a href="#" onClick={this.expand(2)}>
                                    <FontAwesomeIcon icon="table" fixedWidth />
                                    <span className='toolbar-section-title'>Plan Reports</span>
                                    <span className="pull-right-container">
                                        <FontAwesomeIcon icon={(this.state.expanded.indexOf(2) > -1) ? "angle-down" : "angle-left"} fixedWidth />
                                    </span>
                                </a>
                                {this.renderPlanReports()}
                            </li>
                        }
                        {(localStorage.getItem('Lob') != 'Medicare') &&
                            <li className={this.state.expanded.indexOf(3) > -1 ? "treeview active" : "treeview"}>
                                <a href="#" onClick={this.expand(3)}>
                                    <FontAwesomeIcon icon="chart-bar" fixedWidth />
                                    <span className='toolbar-section-title'>Operational Reports</span>
                                    <span className="pull-right-container">
                                        <FontAwesomeIcon icon={(this.state.expanded.indexOf(3) > -1) ? "angle-down" : "angle-left"} fixedWidth />
                                    </span>
                                </a>
                                {this.renderOperationalReports()}
                            </li>
                        }
                        <li className={this.state.expanded.indexOf(4) > -1 ? "treeview active" : "treeview"}>
                            <a href="#" onClick={this.expand(4)}>
                                <FontAwesomeIcon icon="info" fixedWidth />
                                <span className='toolbar-section-title'>Help</span>
                                <span className="pull-right-container">
                                    <FontAwesomeIcon icon={(this.state.expanded.indexOf(4) > -1) ? "angle-down" : "angle-left"} fixedWidth />
                                </span>
                            </a>
                            {this.renderHelp()}
                        </li>
                    </ul>
                </section>
                <div className="sidebar-collapse">
                    {
                        this.state.sidebarOpen === true &&
                        <span onClick={this.minimize} role="button">
                            <FontAwesomeIcon icon="angle-double-left" size="lg" fixedWidth />
                        </span>
                    }
                    {
                        this.state.sidebarOpen === false &&
                        <span onClick={this.minimize} role="button">
                            <FontAwesomeIcon icon="angle-double-right" size="lg" fixedWidth />
                        </span>
                    }
                </div>
            </aside>
        );
    }
}

export default withRouter(connect(
    mapUserToProps
)(Sidebar));