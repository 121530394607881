import React from 'react';
import Axios from 'axios';
import { Button, Col, ControlLabel, FormControl, FormGroup, Row } from 'react-bootstrap';
import toastr from 'toastr';

export default class InclusionExclusionFileUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listName: null,
            fileData: null,
            listNameError: "",
            uniqueNameError: "",
            validationError: []
        };
    }

    handleFileSelection = (e) => {
        e.preventDefault();
        
        this.setState({ 
            listNameError: "",
            uniqueNameError: "",
            validationError : []
        });
        const uploadedFile = e.target.files[0];
       
       
        if (this.validateUpload(uploadedFile)) {
            const fileData = new FormData();
            fileData.append('file', uploadedFile);
            fileData.append('name', uploadedFile.name);
          
            this.setState({ fileData });
        }
       

    }

    handleFileSelectionReset = (e) => {
        e.target.value = null;
    }

    validateUpload = (file) => {
        if (file) {            
            const fileName = file.name.split('.')[0];
            const fileExtension = file.name.split('.').pop();
            const splitString = fileName.split('_');
            const fileType = splitString[splitString.length - 1];
            const selectedListType = this.props.listType + "List";

            const listYear = splitString[0];
            const clientCode = splitString[1];
           
            if( file.size>4e6 || file.size==0)
            {
                this.setState({ listNameError : "Please upload a file smaller than 4 MB and not empty."});
                return false;
            }

            if(splitString.length != 4 && splitString.length != 5)
            {
                this.setState({ listNameError : "Invalid file name"});
                return false;
            }

            var re_listYear = /^([1-9]{1}[0-9]{3})$/;
            var isListYear = re_listYear.exec(listYear);
            if (!isListYear) {
                this.setState({ listNameError : "Make sure you have the correct list year"});
                return false;
            } 

            Axios.get(`/ChaseInclusionExclusion/GetClient/${clientCode}`)
            .then((response) => {             
                if(response.data == -1){
                    this.setState({ listNameError : "Make sure you have the correct client code"});
                    return false;
                }
            })
            .catch((error) => {
                toastr.error("Oops! Something went wrong about client code.");
            });

            if (fileName.includes(selectedListType) == false || fileType != selectedListType) {
                this.setState({ listNameError : "List type does not match the file selected"});
                return false;
            }
            else if (fileName.length > 200) {
                this.setState({ validationError : "File name length must less than 200" });
                return false;
            }
            else if (fileExtension !== "txt" && file.type !== "text/csv") {
                file.style = "danger";
                this.setState({ listNameError : "Invalid file type - must be .txt" });
                return false;
           
            }
        }
        else {
            this.setState({ listNameError : "Invalid file" });
            return false;
        }
        return true;
    }

    uploadDisabled = () => {
        return !(this.props.listName && this.state.fileData);
    }

    uploadFile = () => {
        Axios.post(`api/ChaseInclusionExclusion/?listName=${this.props.listName}&listType=${this.props.listType}`,
            this.state.fileData)
        .then((response) => {
            //todo: display success message
            this.setState({ 
                fileData : null
            })
            toastr.success("File successfully uploaded.");
            this.props.handleModalToggle();
        })
        .catch((error) => {
            if (error.response.status === 409) {
                this.setState({ 
                    uniqueNameError : error.response.data
            })}
            else if (error.response.status === 400) {            
                this.setState({ 
                validationError : error.response.data,
                fileData : null
            })}
            else if (error.response.status === 500) {
                toastr.error("Oops! We are unable to read the file. Please check your format or try again later.")
            }
        });
    }

    render() {
        const errors = this.state.validationError.map((err) =>
        <li>{err}</li>);
        return (
            <Row>
                <Col lg={12}>
                    <FormGroup>
                        <ControlLabel>Upload List</ControlLabel>
                        <FormControl type="file" name="inclusionListUpload" onChange={this.handleFileSelection} onClick={this.handleFileSelectionReset}/>
                        <div style={{ color: "red" }}>{ this.state.listNameError }</div>
                    </FormGroup>
                    <FormGroup style={{ marginTop: "3.3rem" }}>
                        <Button className="upload-button" bsClass="success" onClick={() => this.uploadFile()} disabled={this.uploadDisabled()}>Upload</Button>
                        <div style={{ color: "red" }}>{this.state.uniqueNameError}</div>
                        <div style={{ color: "red", marginTop: "1.1rem" }}>
                            <ul>{errors}</ul>
                        </div>
                    </FormGroup>
                </Col>
            </Row>
        );
    }
}