
export const PAGES = Object.freeze({
	'enrollmentreconciliationreport': 'abc123',
	'financialprofilereport': '3e06df82-66b7-486e-a97a-81d12ad3518b',
	'gapprofilereport': 'a44f0f81-a3c0-4225-9d09-dc7004d151e0',
	'memberprofilereport': 'ed267cc9-1c71-45c5-84a3-7a79650df7a8',
	'planprofilereport': 'c448c7ff-05ff-43fa-8a15-14e8819989c8',
	'providerprofilereport': '49160edc-178b-4f98-ae7c-a00638935b35',
	'useractivity': '382649e4-5cc9-4ea8-8a7f-7a54c5f40434'
});

export const MapUserAccess = (authorized) => {
	return authorized.map(x => PAGES[x.replace(/\s/g, '')]);
}

export const MapAllPages = () => {
	return Object.keys(PAGES).map(x => PAGES[x]);
}