
import React from 'react';
import { Route, Redirect, Switch } from 'react-router';
import App from '../App';
import AuthWrapper from '../components/Shared/AuthWrapper';
import ErrorPages from '../components/Error';
import AdminApp from '../components/AdminApp';
import AdminLogin from '../components/Admin/Login';
import { MemberDispatcher } from '../components/Admin/MemberDispatcher';
import { CreateNewJob, EngineJobManagement } from '../components/Admin/EngineJobManagement';
import InclusionExclusionContainer from '../components/GapChaseList/InclusionExclusion/InclusionExclusionContainer';
import ListContainer from '../components/GapChaseList/ListContainer';
import { PAGES } from './pages';
import { logout } from '../utils/Window';

//  EnrollmentReconciliationDetail
import { EnrollmentReconciliationReport } from '../components/Reporting/EnrollmentReconciliation';
import { EnrollmentReconciliationDetail } from '../components/Reporting/EnrollmentReconciliation';

import { FinancialProfileReport, FinancialProfileDetail } from '../components/Reporting/FinancialProfile';
import { GapProfileReport } from '../components/Reporting/GapProfile';
import { MemberProfileReport, MemberDetail } from '../components/Reporting/MemberProfile';
import { PlanProfileReport, PlanProfileDetail } from '../components/Reporting/PlanProfile';
import { ProviderProfileReport, ProviderDetailReport } from '../components/Reporting/ProviderProfile';
import { UserActivityReport } from '../components/Reporting/UserActivity';
import { UserManual } from '../components/Help';

import { Config, FRUser, TokenManager, UserManager } from '@forgerock/javascript-sdk';

Config.set({

    serverConfig: {

        baseUrl: window.REACT_APP_IAM_URL ?? "",
        timeout: parseFloat(window.REACT_APP_TIMEOUT ?? "0"),
    },

    realmPath: window.REACT_APP_REALM_PATH,

    clientId: window.REACT_APP_CLIENTID,

    redirectUri: window.REACT_APP_CURRENT_URL,//document.location.origin ,

    scope: "openid profile",

});

export default (
    <Switch>
        {/* Admin Routes */}
        <Route exact path='/Admin'
            render={() => <Redirect to="/Admin/MemberDispatcher" />} />
        <Route exact path='/Admin/Login'
            render={(props) => <AdminApp {...props}><AdminLogin /></AdminApp>} />
        <Route exact path='/Admin/MemberDispatcher'
            render={(props) => <AdminApp {...props}><MemberDispatcher /></AdminApp>} />
        <Route exact path='/Admin/EngineJobManagement'
            render={(props) => <AdminApp {...props}><EngineJobManagement /></AdminApp>} />
        {/* Main App Routes */}
        <App>
            <Switch>
                {/* Root */}
                <Route
                    exact
                    path='/'
                    render={() => {
                        async function loadLogin() {
                            try {

                                await TokenManager.getTokens({
                                    login: "redirect"
                                }).then((data) => {
                                    if (data) {
                                        UserManager.getCurrentUser().then((usr) => {
                                            if (usr.group.filter(role => role !== '').length > 0) {
                                                localStorage.setItem('FRToken', data.idToken);
                                                localStorage.setItem('username', usr.name);
                                                localStorage.setItem('userId', usr.subname);
                                                localStorage.setItem('email', usr.email);
                                                //localStorage.setItem("roles", JSON.stringify(usr.group));
                                                //let clientName = this.extractClient(usr.group)
                                                //localStorage.setItem("clientName", clientName)

                                                var cleanedRoles = usr.group.map(role => {
                                                    const indexOfColon = role.indexOf(':');
                                                    if (indexOfColon !== -1) {
                                                        return role.substr(indexOfColon + 1).toLowerCase();
                                                    } else {
                                                        return role.toLowerCase();
                                                    }

                                                })
                                                console.log(cleanedRoles);
                                                cleanedRoles = cleanedRoles.filter(item => item);
                                                localStorage.setItem("roles", cleanedRoles);
                                            }
                                        })
                                            .catch((err) => {
                                                console.log(err);
                                                localStorage.clear();

                                            })
                                    }
                                }

                                )
                            }
                            catch (err) {
                                console.log(err)
                            }
                        }

                        if (!localStorage.getItem('FRToken')) {
                            loadLogin();
                        }

                        const redirectPath = '/GapChase/Lists/Gap';

                        return <Redirect to={redirectPath} />;
                    }}
                />

                {/* Reporting */}


                <Route exact path='/Reporting/UserActivity'
                    render={(props) => <AuthWrapper {...props} component={UserActivityReport} page={PAGES.useractivity} />} />

                <Route exact path='/Reporting/ProviderProfile'
                    render={(props) => <AuthWrapper {...props} component={ProviderProfileReport} page={PAGES.providerprofilereport} />} />

                {/* todo: test route :providerId access */}
                <Route exact path='/Reporting/ProviderProfile/:providerId/:hiosId/:market/:year'
                    render={(props) => <AuthWrapper {...props} component={ProviderDetailReport} page={PAGES.providerprofilereport} />} />

                <Route exact path='/Reporting/MemberProfile'
                    render={(props) => <AuthWrapper {...props} component={MemberProfileReport} page={PAGES.memberprofilereport} />} />

                <Route exact path='/Reporting/MemberProfile/:memberId'
                    render={(props) => <AuthWrapper {...props} component={MemberDetail} page={PAGES.memberprofilereport} />} />

                <Route exact path='/Reporting/PlanProfile'
                    render={(props) => <AuthWrapper {...props} component={PlanProfileReport} page={PAGES.planprofilereport} />} />

                <Route exact path='/Reporting/EnrollmentReconciliation'
                    render={(props) => <AuthWrapper {...props} component={EnrollmentReconciliationReport} page={PAGES.enrollmentreconciliationreport} />} />

                <Route exact path='/Reporting/EnrollmentReconciliation/:viewType/:market/:planId/:hiosId/:year/:month'
                    render={(props) => <AuthWrapper {...props} component={EnrollmentReconciliationDetail} page={PAGES.enrollmentreconciliationreport} />} />

                <Route exact path='/Reporting/PlanProfile/:planId/:year'
                    render={(props) => <AuthWrapper {...props} component={PlanProfileDetail} page={PAGES.planprofilereport} />} />

                <Route exact path='/Reporting/GapProfile'
                    render={(props) => <AuthWrapper {...props} component={GapProfileReport} page={PAGES.gapprofilereport} />} />

                <Route exact path='/Reporting/FinancialProfile'
                    render={(props) => <AuthWrapper {...props} component={FinancialProfileReport} page={PAGES.financialprofilereport} />} />

                <Route exact path='/Reporting/FinancialProfile/:year/:hiosId/:market/:planId'
                    render={(props) => <AuthWrapper {...props} component={FinancialProfileDetail} page={PAGES.financialprofilereport} />} />

                {/* Gap + Chase List -- note: key props are set on these routes to force a remount on route change */}
                <Route exact path='/GapChase/Lists/:listType/New'
                    render={(props) => <AuthWrapper {...props} component={ListContainer} key={window.location.href} showFilterSidebar={true} showLists={false} />} />

                <Route exact path='/GapChase/Lists/:listType/New/:gapId'
                    render={(props) => <AuthWrapper {...props} component={ListContainer} key={window.location.href} showFilterSidebar={true} showLists={false} />} />

                <Route exact path='/GapChase/Lists/:listType'
                    render={(props) => <AuthWrapper {...props} component={ListContainer} key={window.location.href} />} />

                <Route exact path='/GapChase/Lists/:listType/:id'
                    render={(props) => <AuthWrapper {...props} component={ListContainer} key={window.location.href} showFilterSidebar={true} showLists={false}
                        showUpdate={true} />} />

                <Route exact path='/GapChase/InclusionExclusion'
                    render={(props) => <AuthWrapper {...props} component={InclusionExclusionContainer} key={window.location.href} />} />

                <Route exact path='/Help/UserManual'
                    render={(props) => <UserManual {...props} />} />

                {/* Misc. */}
                <Route path='/Unauthorized' component={ErrorPages.Unauthorized} />
                <Route path='/Login' component={ErrorPages.Login} />
                <Route path='/Logout' render={logout} />
                <Route component={ErrorPages.NotFound} />
            </Switch>
        </App>
    </Switch>
)
