import { FRUser, UserManager, TokenManager } from '@forgerock/javascript-sdk';

export const redirectToPortal = () => {
    try {
        TokenManager.getTokens({ login: 'redirect' })
            .then(async (data) => {
                localStorage.setItem('FRToken', data.idToken);
                UserManager
                    .getCurrentUser()
                    .then((usr) => {
                        if (usr.group.filter(role => role !== '').length > 0) {
                            localStorage.setItem('FRToken', data.idToken);
                            localStorage.setItem('username', usr.name);
                            localStorage.setItem('userId', usr.subname);
                            localStorage.setItem('email', usr.email);
                            //localStorage.setItem("roles", JSON.stringify(usr.group));
                            //let clientName = this.extractClient(usr.group)
                            //localStorage.setItem("clientName", clientName)

                            var cleanedRoles = usr.group.map(role => {
                                const indexOfColon = role.indexOf(':');
                                if (indexOfColon !== -1) {
                                    return role.substr(indexOfColon + 1).toLowerCase();
                                } else {
                                    return role.toLowerCase();
                                }

                            })
                            console.log(cleanedRoles);
                            cleanedRoles = cleanedRoles.filter(item => item);
                            localStorage.setItem("roles", cleanedRoles);
                        }

                    }

                    );
            });
    }
    catch (err) {
        console.log(err)
    }
};

export const redirectToLanding = () => {
    if (window.NODE_ENV === 'development') {
        window.location = window.REACT_APP_CURRENT_URL_DEV;
    }
    else {
        window.location = window.REACT_APP_CURRENT_URL_PROD;
    }
};

export const redirect = (url) => {
    if (url) {
        window.location = url;
    }
};

export const logout = () => {
    //remove all local storage user and token info
    FRUser.logout().then(() => {
        localStorage.clear();
        redirectToPortal();;
    })

}
