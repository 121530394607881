import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { mapUserToProps } from '../../redux/actions';
import { toggleClass } from '../../utils/Document';

class Header extends Component {
    toggleNavigation = (e) => {
        e.preventDefault();
        toggleClass('main', 'sidebar-open');
    }

    render() {
        return (
            <header className="main-header">
                <nav className="navbar navbar-static-top" role="navigation">
                    <Link to="/" className="logo">
                        <span className="logo-lg img-logo-margin-fix">
                            <img
                                src="../../images/UST_HealthProof_logo.svg"
                                alt="UST_HealthProof_logo"
                                title="UST_HealthProof_logo"
                                height="41"
                                width="220"
                            />
                        </span>
                    </Link>
                    <div className="lob">
                        <span > {localStorage.getItem('Lob')}</span>
                    </div>
                    <a href="#" className="sidebar-toggle" onClick={this.toggleNavigation}>
                        <FontAwesomeIcon icon="bars" />
                    </a>
                    <div className="navbar-custom-menu">
                        <ul className="nav navbar-nav">
                            <li className="user user-menu">
                                {
                                    this.props.user &&
                                    <span className="user-name">
                                        {this.props.user.userName}
                                    </span>
                                }
                            </li>
                            <li className="navbar-btn-container">
                                <span className="navbar-btn">
                                    <Link to="/Logout" className="btn btn-default btn-flat">
                                        Sign out
                                    </Link>
                                </span>
                            </li>
                        </ul>
                    </div>
                </nav>
            </header>
        );
    }
}

export default connect(
    mapUserToProps
)(Header);
