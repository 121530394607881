import axios from 'axios';
import { addLoader, removeLoader } from '../utils/Document';

// note: if additional apis are to be used, those urls can be configured below as part of the request interception
axios.defaults.baseURL = window.NODE_ENV === 'development' ?
    window.REACT_APP_API_URL_DEV : window.REACT_APP_API_URL_PROD;

axios.interceptors.request.use((config) => {
    addLoader();
    return config;
}, (error) => {
    removeLoader();
    return Promise.reject(error);
});

axios.interceptors.response.use((response) => {
    removeLoader();
    return response;
}, (error) => {
    removeLoader();
    // todo: when we have actual api endpoints set up, find status on error and redirect to unauthorized if 401.
    return Promise.reject(error);
});