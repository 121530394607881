import React from 'react';
import Axios from 'axios';
import { clone } from 'lodash';
import { Button, Glyphicon, Modal, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { inclusionExclusionListColumns } from '../DefaultColumns';
import InclusionExclusionUploadModal from './InclusionExclusionUploadModal';
import { Records, Sort } from '../TableComponents';
import { Error } from '../Messages';
import FileDownload from 'js-file-download';
import toastr from 'toastr';

export default class InclusionExclusionLists extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lists: null,
            paginationStats: {
                CountPerPage: 10,
                Page: 0,
                RecordCount: 0
            },
            sortOrder: [],
            error: false,
            loading: true,
            showUploadModal: false,
            showDeleteModal: false,
            deletedListID: 0
        };

        this.timer = null;
        this.columns = inclusionExclusionListColumns();
        const CancelToken = Axios.CancelToken;
        this.source = CancelToken.source();
    }

    componentDidMount() {
        this.loadLists();
    }

    componentWillUnmount() {
        this.source.cancel();
        clearTimeout(this.timer);
    }

    //todo
    loadLists = (page = this.state.paginationStats.Page, pageSize = this.state.paginationStats.CountPerPage) => {
        clearTimeout(this.timer);
        const sortOrder = [];
        let sortColumns = clone(this.columns);

        sortColumns.sort((a, b) => { return a.sortOrder > b.sortOrder }).forEach((item) => {
            if (item.sortDirection !== undefined && item.sortDirection.length && item.sortOrder > 0) {
                sortOrder.push(`${item.fieldName} ${item.sortDirection}`);
            }
        });

        //todo -- add mock data
        // this.setState({
        //     lists: [],
        //     paginationStats: {
        //         CountPerPage: 10,
        //         Page: 0,
        //         RecordCount: 0
        //     },
        //     loading: false
        // });

        Axios.get(`/api/ChaseInclusionExclusion`, {
            params: {
                page: page + 1,
                pageSize: pageSize,
                sortOrder: sortOrder.join(",")
            },
            cancelToken: this.source.token
        })
            .then((response) => {
                if (response.status === 200) {
                    this.setState({
                        lists: response.data.results,
                        paginationStats: {
                            CountPerPage: pageSize,
                            Page: response.data.pageNumber - 1,
                            RecordCount: response.data.totalCount
                        },
                        loading: false
                    });
                }
            })
            .catch((error) => {
                if (!Axios.isCancel(error)) {
                    // handle error
                    this.setState({
                        error: true,
                        loading: false
                    });
                }
            });
    }

    handleSort = (sortData, e) => {
        this.columns.forEach((item, index) => {
            if (item.fieldName === sortData.fieldName) {
                this.columns[index].sortOrder = 1;
                this.columns[index].sortDirection = sortData.sortDirection;
            }
            else {
                if (item.sortOrder === undefined) {
                    item.sortOrder = 0;
                }
                this.columns[index].sortOrder = 0;
                this.columns[index].sortDirection = "";
            }
        });
        this.pageChange(0);
    }

    pageChange = (idx) => {
        this.loadLists(idx, this.state.paginationStats.CountPerPage);
    }

    pageCountChange = (countPerPage) => {
        this.loadLists(0, countPerPage);
    }

    handleListDelete = () => {
        Axios.delete(`/api/ChaseInclusionExclusion/${this.state.deletedListID}`, {
            cancelToken: this.source.token
        })
            .then(() => {
                this.loadLists();
                this.toggleDeleteModal();
            });
    }

    toggleDeleteModal = (listID) => {
        this.setState(prevState => ({
            showDeleteModal: !prevState.showDeleteModal,
            deletedListID: listID ? listID : 0
        }));
    }

    getCurrentTimestamp = () => {
        const date = new Date();
        return `${date.getFullYear()}${String(date.getMonth() + 1).padStart(2, '0')}${''
            }${String(date.getDate()).padStart(2, '0')}${String(date.getHours()).padStart(2, '0')}${''
            }${String(date.getMinutes()).padStart(2, '0')}${String(date.getSeconds()).padStart(2, '0')}`;
    }

    //export list to csv
    handleExportCsv = (listID, listName) => {
        Axios.get(`/ChaseInclusionExclusion/${listID}/ExportCSV`, {
            cancelToken: this.source.token
        })
            .then((response) => {
                FileDownload(response.data, `${listName}_${this.getCurrentTimestamp()}.txt`);
            })
            .catch((error) => {
                if (!Axios.isCancel(error)) {
                    // handle error
                    toastr.error('Oops! Something went wrong.');
                    this.setState({ error: true });
                }
            });
    }

    //todo
    renderLink = (item) => {
        return (
            <Link to={`/GapChase/Lists/${this.props.listType}/${item.id}`}>
                {item.name}
            </Link>
        );
    }

    //todo
    renderActions = (item) => {
        return (
            <td style={{ whiteSpace: "nowrap" }}>
                <Button onClick={() => this.handleExportCsv(item.MemberProviderListId, item.ListName)}>
                    <Glyphicon glyph="save" />
                </Button>
                {this.props.user && (item.CreatedBy === this.props.user.Email
                    || this.props.user.Roles.includes('commercialacagapchaseadmin')) ? (item.IsActive ?
                        <Button title="List in use by a gap or chase list - cannot delete" disabled="true"><Glyphicon glyph="lock" /></Button> :
                        <Button onClick={() => this.toggleDeleteModal(item.MemberProviderListId)}><Glyphicon glyph="remove" /></Button>)
                    : null
                }
            </td>
        );
    }

    //todo
    renderResults = () => {
        if (!this.state.loading && !this.state.error) {
            return (
                <div style={{ minHeight: "400px" }}>
                    <Table hover bordered condensed striped responsive>
                        <thead>
                            <tr>
                                {
                                    this.columns.map((item, index) => {
                                        return (
                                            <th key={`${item.fieldName}-th`} className={item.className} style={{ height: "1px" }}>
                                                <Sort onSort={this.handleSort} fieldName={item.fieldName} sortDirection={item.sortDirection}
                                                    text={item.displayName} sortable={item.sortable} />
                                            </th>
                                        );
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.lists &&
                                this.state.lists.map((item, index) => {
                                    return (
                                        <tr className="text-left" key={item.MemberProviderListId}>
                                            <td>{item.ListName}</td>
                                            <td>{item.ListType}</td>
                                            <td>Completed</td>
                                            <td>{item.CreatedBy}</td>
                                            <td>{new Date(item.CreatedDatetime).toLocaleDateString('en-US')}</td>
                                            {this.renderActions(item)}
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </Table>
                </div>
            );
        }
        else {
            return (
                <div style={{ minHeight: "400px" }}>
                    <Error />
                </div>
            );
        }
    }

    toggleUploadModal = () => {
        this.setState(prevState => ({
            showUploadModal: !this.state.showUploadModal
        }));
    }

    renderUploadButton = () => {
        return (
            <div style={{ height: "5rem" }}>
                <Button onClick={this.toggleUploadModal} style={{ float: "right" }}>
                    Upload New List
                    <Glyphicon glyph="upload" style={{ marginLeft: "0.66rem", top: "2.8px", lineHeight: "14px", color: "#6c716c" }} />
                </Button>
            </div>
        );
    }
    renderDeleteConfirmationModal = () => {
        const confirmButton = (
            <Button bsStyle="success" onClick={this.handleListDelete} style={{ float: "right" }}>
                OK
                <Glyphicon glyph="ok" style={{ marginLeft: "0.66rem", lineHeight: "14px", float: "right" }} />
            </Button>
        );
        return (
            <Modal show={this.state.showDeleteModal} onHide={this.toggleDeleteModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete this list?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    {confirmButton}
                </Modal.Footer>
            </Modal>
        );
    }

    render() {
        return (
            <div>
                {this.renderUploadButton()}
                <section>
                    <div className="panel panel-default">
                        <div className="panel-heading">
                            <h3>Inclusion/Exclusion Lists</h3>
                        </div>
                        <div className="panel-body" style={{ minHeight: "400px" }}>
                            {this.state.loading ? null : this.renderResults()}
                            <Records stats={this.state.paginationStats} onPageChangeSize={this.pageCountChange} onPage={this.pageChange} />
                        </div>
                    </div>
                </section>
                <InclusionExclusionUploadModal visible={this.state.showUploadModal} handleModalToggle={this.toggleUploadModal} />
                {this.renderDeleteConfirmationModal()}
            </div>
        );
    }
}