import axios from 'axios';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Header, Footer, Sidebar } from './components/PageLayout';
import FullPageLoader from './components/Shared/FullPageLoader';
import { MapUserAccess, MapAllPages } from './config/pages';
import { mapUserDispatchToProps, mapUserStateToProps } from './redux/actions';
import { Config, FRUser, TokenManager, UserManager } from '@forgerock/javascript-sdk';

Config.set({

    serverConfig: {

        baseUrl: window.REACT_APP_IAM_URL ?? "",
        timeout: parseFloat(window.REACT_APP_TIMEOUT ?? "0"),
    },

    realmPath: window.REACT_APP_REALM_PATH,

    clientId: window.REACT_APP_CLIENTID,

    redirectUri: window.REACT_APP_CURRENT_URL,//document.location.origin ,

    scope: "openid profile",

});

const url = new URL(document.location.toString());
const params = url.searchParams;
const authCode = params.get('code');
const state = params.get('state');

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            initialized: false
        }
    }

    componentWillMount = () => {
        this.checkAuthorization()
            .then(new Promise((resolve, reject) => {
                return this.getLob(resolve, reject)
            }))
            .then(this.initializationCompleted)

            .catch(this.initializationCompleted);
    }
      
    checkAuthorization = () => {
        return new Promise((resolve, reject) => {
            const storedToken = localStorage.getItem('FRToken');
                let query;
                if (window.location.search) {
                    query = window.location.search;
                }
                else {
                    query = window.location.href.indexOf('?') > -1 ?
                        window.location.href.split('?')[1] : '';
                }
                const params = new URLSearchParams(query);
                let jwt = params.get('jwt');

                if (jwt) {
                    jwt = jwt.replace(/"/g,"");
                    axios.defaults.headers.common = {
                        'Authorization':   jwt
                    };

                    localStorage.setItem('FRToken', jwt);
                    return this.getUserData(resolve, reject);
                }
                else if (storedToken) {
                    axios.defaults.headers.common = {
                        'Authorization' :  storedToken
                    };

                    return this.getUserData(resolve, reject);
                }
                else {
                    if (state && authCode) {
                        this.authorize(authCode, state);
                    }
                    else {
                        this.redirectToLogin();
                    }
                   
                }
                 return resolve("Complete");
        });
    }

    getUserData = (resolve, reject) => {
        const user = { userId: '', userName: '', email: '' };
        user.userId = localStorage.getItem('userId');
        user.userName = localStorage.getItem('username');
        user.email = localStorage.getItem('email');
        var roles = localStorage.getItem("roles");
        let userRoles = new Array();
        userRoles = roles.split(',');
        user.Roles = userRoles;
        if (user.Roles.indexOf('Administrators') > -1) {
            user.AuthorizedPages = MapAllPages();
        }
        else {
            user.AuthorizedPages = MapUserAccess(user.Roles);
        }

        console.log(user.AuthorizedPages);

        console.log(user.Roles); // []
        //persist user information in "session" redux store
        this.props.setUser(user);

        // The code below appears to be redundant, but raise 
        // Veracode issue "URL Redirection to Untrusted Site ('Open Redirect')"
        // window.location = window.location.href.split("?")[0];

        return resolve("Complete");
    }

    getLob = (resolve, reject) => {
        axios.get('/v1/settings')
            .then((response) => {

                localStorage.setItem('Lob', response.data.LineOfBusiness);

                return resolve("Complete");
            })
            .catch((error) => {
                //this.redirectToLogin();
                return resolve("Complete");
            });
    }

    initializationCompleted = () => {
        this.setState({ initialized: true });
    }

    redirectToLogin =()=> {
          try {
          TokenManager.getTokens({
            login: "redirect"
          }).then((data) => {
            if (data) {
                UserManager.getCurrentUser().then((usr) => {
                    if (usr.group.filter(role => role !== '').length > 0) {
                        localStorage.setItem('FRToken', data.idToken);
                        localStorage.setItem('username', usr.name);
                        localStorage.setItem('userId', usr.subname);
                        localStorage.setItem('email', usr.email);

                        var cleanedRoles = usr.group.map(role => {
                            const indexOfColon = role.indexOf(':');
                            if (indexOfColon !== -1) {
                                return role.substr(indexOfColon + 1).toLowerCase();
                            } else {
                                return role.toLowerCase();
                            }

                        })
                        console.log(cleanedRoles);
                        cleanedRoles = cleanedRoles.filter(item => item);
                        localStorage.setItem("roles", cleanedRoles);
                        var lob = 'Medicare';
                        if(!localStorage.getItem('Lob'))
                        {
                            if (window.REACT_APP_CURRENT_URL.includes('ma')) {
                                lob = 'Medicare';
                            }
                            else if (window.REACT_APP_CURRENT_URL.includes('localhost')) {
                                lob = 'Medicare';
                            }
                            else {
                                lob = 'Commercial';
                            }
                            localStorage.setItem('Lob', lob);
                        }
                        else
                        {
                            lob=localStorage.getItem('Lob');
                        }
                        const redirectPath = '/#/GapChase/Lists/Gap';
                        window.location =redirectPath;
                        //document.location.assign( redirectPath);


                    }
                })
                    .catch((err) => {
                        console.log(err);
                        localStorage.clear();

                    })
              }
        })               
    }
    catch (err) {
        console.log(err)
    }
}

     authorize = (code, state)   => {
        try {
             TokenManager.getTokens({ query: { code, state } })
                .then(async (data) => { 
                    await UserManager.getCurrentUser().then((usr) => {
                        localStorage.setItem('FRToken', data.idToken);
                        localStorage.setItem('username', usr.name);
                        localStorage.setItem('userId', usr.subname);
                        localStorage.setItem('email', usr.email);

                        var cleanedRoles = usr.group.map(role => {
                            const indexOfColon = role.indexOf(':');
                            if (indexOfColon !== -1) {
                                return role.substr(indexOfColon + 1).toLowerCase();
                            } else {
                                return role.toLowerCase();
                            }

                        })
                        console.log(cleanedRoles);
                        cleanedRoles = cleanedRoles.filter(item => item);
                        localStorage.setItem("roles", cleanedRoles);
                        var lob = 'Medicare';
                        
                        if(!localStorage.getItem('Lob'))
                        {
                            if (window.REACT_APP_CURRENT_URL.includes('ma'))
                            {
                                lob = 'Medicare';
                            }
                            else if (window.REACT_APP_CURRENT_URL.includes('localhost'))
                            {
                                lob = 'Medicare';
                            }
                            else
                            {
                                lob = 'Commercial';
                            }
                            localStorage.setItem('Lob', lob);
                        }
                        else
                        {
                            lob = localStorage.getItem('Lob');
                        }
                        const redirectPath = '/#/GapChase/Lists/Gap';
                        window.location =redirectPath;
                        //document.location.assign( redirectPath);
                    });
                });
        }
        catch (err) {
            alert(111);
            document.location.assign(`${document.location.origin}/login`);
        }

    }

    render() {
        return (
            <div className='wrapper'>
                <Header />
                <Sidebar />
                <div className='content-wrapper' style={{ overflowY: 'scroll' }}>
                    {
                        this.state.initialized &&
                        this.props.children
                    }
                </div>
                <Footer />
                <FullPageLoader />
            </div>
        )
    }
}



export default connect(
  mapUserStateToProps,
  mapUserDispatchToProps
)(App);
